import React from "react";
import "./styles.css";

import logo_topo from "../../assets/logo_topo.png";
import CtaWpp from "../CTAWpp";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="right">
          <CtaWpp text={"(11) 96069-1730"} icon={true} />
          <CtaWpp text={"(11) 95723-2011"} number={"11957232011"} icon={true} />
        </article>
      </section>
    </nav>
  );
};

export default Menu;
