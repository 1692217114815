import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import Line from "../../components/Line";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about2 from "../../assets/about2.png";
import about2Mobile from "../../assets/mobile/about2.png";
import about3 from "../../assets/about3.png";
import about4_1 from "../../assets/about4_1.png";
import about4_2 from "../../assets/about4_2.png";
import about4_3 from "../../assets/about4_3.png";
import about4_4 from "../../assets/about4_4.png";
import about5 from "../../assets/about5.png";
import about5Mobile from "../../assets/mobile/about5.png";
import about6_1 from "../../assets/about6_1.png";
import about6_2 from "../../assets/about6_2.png";
import about6_3 from "../../assets/about6_3.png";
import about7 from "../../assets/about7.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Nossos Serviços</h2>

          <Line />

          <p className="text1">Veja os principais serviços que oferecemos</p>

          <p className="text2">
            Não importa o tamanho e complexidade do seu projeto, temos a solução
            ideal para você!
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Fachadas" />
              </figure>

              <p className="item_title">Fachadas</p>

              <p className="item_text">
                Revestimentos que podem se alterados ou modificados com
                facilidade.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="Interiores" />
              </figure>

              <p className="item_title">Interiores</p>

              <p className="item_text">
                Aplicação internas para a mordenização do ambiente.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_3} alt="Coberturas" />
              </figure>

              <p className="item_title">Coberturas</p>

              <p className="item_text">
                Revestimentos de coberturas e residenciais.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_4} alt="Identidade" />
              </figure>

              <p className="item_title">Identidade</p>

              <p className="item_text">
                Projetos de identificação e criação da identidade visual.
              </p>
            </div>
          </div>

          <p className="text">
            Garanta agora um projeto exclusivo e sofisticado para o seu lar ou
            empresa!
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about2Mobile
                  : about2
              }
              alt="Garantia de qualidade e satisfação"
            />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">Porque Nos Escolher?</h2>

          <Line />

          <div className="gallery">
            <figure className="left">
              <img src={about3} alt="Homens trabalhando" />
            </figure>

            <div className="right">
              <p className="text1">O melhor para a segurança de todos</p>

              <p className="text">
                Os produtos da <b> Meta3 </b>são pensados em oferecer a maior
                segurança para sua empresa,seus consumidores e sua família.
              </p>

              <CtaWpp />
            </div>
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container vertical">
          <h2 className="title">Diferencial do Nosso Serviço</h2>

          <Line />

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about4_1} alt="Até 15 Anos de Garantia" />
              </figure>

              <p className="item_title">Até 15 Anos de Garantia</p>

              <p className="item_text">
                Nossos materiais proporcionam até 15 anos de garantia em nossa
                linha premium.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about4_2} alt="Proteção Raios U.V." />
              </figure>

              <p className="item_title">Proteção Raios U.V.</p>

              <p className="item_text">
                Ação de não deterioração mesmo em ambientes com constantes
                exposição aos raios solares.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about4_3} alt="Não Emite Gases Tóxicos" />
              </figure>

              <p className="item_title">Não Emite Gases Tóxicos</p>

              <p className="item_text">
                Mesmo sob alta temperaturas as placas não emitem gases tóxicos.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about4_4} alt="Resistência a chuva" />
              </figure>

              <p className="item_title">Resistência a chuva</p>

              <p className="item_text">
                Os nossos materiais tem capacidade de não oxidação mesmo a longa
                exposição.
              </p>
            </div>
          </div>

          <p className="text">
            Garanta agora um projeto exclusivo e sofisticado para o seu lar ou
            empresa!
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about5">
        <div className="container vertical">
          <h2 className="title">Nossas Obras Recentes</h2>

          <p className="text">
            Com total domínio por processos de desenvolvimento de produtos que
            facilitam a execução dos mais complexos projetos, cuidamos de todas
            as etapas da obra nos preocupando com cada detalhe e oferecendo
            atendimento personalizado de acordo com cada cliente.
          </p>

          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about5Mobile
                  : about5
              }
              alt="Fotos de obras recentes"
            />
          </figure>

          <p className="text1">
            Não importa o tamanho e complexidade do seu projeto, temos a solução
            ideal para você!
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about6">
        <div className="container vertical">
          <h2 className="title">Depoimentos</h2>

          <Line />

          <p className="text1">O que os clientes pensam sobre nós?</p>

          <p className="text">
            Nós da Meta3 focamos em trabalhar sempre com excelência,
            comprometimento inovação.
          </p>

          <div className="gallery">
            <figure className="item">
              <img src={about6_1} alt="Depoimento 1" />
            </figure>

            <figure className="item">
              <img src={about6_2} alt="Depoimento 2" />
            </figure>

            <figure className="item">
              <img src={about6_3} alt="Depoimento 3" />
            </figure>
          </div>
        </div>
      </article>

      <article className="about7">
        <figure className="left">
          <img src={about7} alt="Meta 3" />
        </figure>

        <div className="right">
          <h2 className="title">Fale Conosco Agora Mesmo!</h2>

          <p className="text">
            Não importa o tamanho e complexidade do seu projeto, temos a solução
            ideal para você!
          </p>

          <div className="cta">
            <CtaWpp text={"(11) 96069-1730"} />
            <CtaWpp text={"(11) 95723-2011"} number={"11957232011"} />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
