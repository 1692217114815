import { useEffect, useState } from "react";
import "./styles.css";

import iconwhats from "../../assets/iconwhats.png";
import iconwhats2 from "../../assets/iconwhats2.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("SOLICITE UM ORÇAMENTO");
  const [number, setNumber] = useState("11960691730");
  const [icon, setIcon] = useState(iconwhats);

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    if (props.number !== undefined) setNumber(props.number);
    if (props.icon !== undefined) setIcon(iconwhats2);
  }, [props.text, props.number, props.icon]);

  return (
    <button id="ctaWpp">
      <a
        href={`http://wa.me/+55${number}?text=Olá,%20Gostaria%20de%20saber%20mais`}
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
