import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";
import Line from "../../components/Line";

import header2 from "../../assets/header2.png";
import header3 from "../../assets/header3.png";
import header3_1 from "../../assets/mobile/header3_1.png";
import header3_2 from "../../assets/mobile/header3_2.png";
import header3_3 from "../../assets/mobile/header3_3.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Somos Pioneiros em <br />
            <span>Revestimento Metálico</span>
          </h1>

          <p className="text">
            Aprimore o ambiente da sua casa ou empresa com projetos exclusivos
            de revestimentos metálicos, garantindo segurança, durabilidade e
            sofisticação.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <figure className="left">
            <img src={header2} alt="Homens trabalhando" />
          </figure>

          <div className="right">
            <h2 className="title">Sobre Nós</h2>

            <p className="text">
              <b>
                Sempre atrás do nossos objetivos, e abertos a sugestões de
                nossos clientes!
              </b>
            </p>

            <figure className="mobile">
              <img src={header2} alt="Homens trabalhando" />
            </figure>

            <p className="text">
              Para que isso aconteça, contamos com uma fábrica especializada no
              seguimento e uma equipe com ampla experiência formada por
              arquitetos, engenheiros, administradores e profissionais altamente
              qualificados.
            </p>

            <p className="text">
              Treinados para solucionar qualquer percalço que possa aparecer ao
              decorrer de cada projeto.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">Algumas de Nossas Obras</h2>

          <Line />

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={1}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="gallery">
              <figure>
                <img src={header3} alt="Galeria de imagens" />
              </figure>
            </div>
          )}

          <p className="text">
            Garanta agora um projeto exclusivo e sofisticado para o seu lar ou
            empresa!
          </p>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
