import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import iconmail from "../../assets/iconmail.png";
import iconwhats from "../../assets/iconwhats.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="logo">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="middle">
            <p className="item_title">eNDEREÇO:</p>

            <p className="text">
              Av. José Miguel Ackel, 2429 - Jardim Guilhermino, Guarulhos - SP,
              07273-000
            </p>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.9746832436726!2d-46.42274312467288!3d-23.461377678868626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce6247800c5a4f%3A0x3ccb3414edc8b76!2sAv.%20Jos%C3%A9%20Miguel%20Ackel%2C%202429%20-%20Jardim%20Guilhermino%2C%20Guarulhos%20-%20SP%2C%2007273-000!5e0!3m2!1spt-BR!2sbr!4v1719177351050!5m2!1spt-BR!2sbr"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
            className="map"
          ></iframe>

          <div className="right">
            <p className="item_title">CONTATOS:</p>

            <div className="ctas">
              <a
                className="cta"
                href="http://wa.me/+5511960691730?text=Olá,%20Gostaria%20de%20saber%20mais"
                target="_blank"
                rel="noreferrer"
              >
                <figure>
                  <img src={iconwhats} alt="Ícone de Whatsapp" />
                </figure>

                <p className="text">(11) 96069-1730</p>
              </a>

              <a
                className="cta"
                href="http://wa.me/+5511957232011?text=Olá,%20Gostaria%20de%20saber%20mais"
                target="_blank"
                rel="noreferrer"
              >
                <figure>
                  <img src={iconwhats} alt="Ícone de Whatsapp" />
                </figure>

                <p className="text">(11) 95723-2011</p>
              </a>

              <a className="cta" href="mailto:meta3.comercial@gmail.com">
                <figure>
                  <img src={iconmail} alt="Ícone de Email" />
                </figure>

                <p className="text">meta3.comercial@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Copyright 2024Meta3 - Todos os direitos reservados
          </p>

          <p className="text">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
